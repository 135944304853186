@use '../config/' as *;
.waf-bracket-table {
  padding: 0;
  margin: 0 var(--half-space-negative);
  line-height: 1;
  .layout-wrapper {
    @extend %p-x-full;
  }
  .waf-head {
    margin-bottom: 3.6rem;
  }
  .title {
    @extend %c-blue-10;
    @extend %font-20-psb;
  }
  .head-tab {
    @extend %d-none;
  }
  .table {
    border: 0;
    @extend %flex;
    &-left,
    &-right {
      .table-body .table-row {
        border-bottom: .1rem solid var(--c-light-grey-1);
        &:nth-child(odd) {
          border-top: .1rem solid var(--c-light-grey-1);
          @extend %c-light-grey-1-bg-2;
        }
      }
    }
    &-left {
      border-left: .1rem solid var(--c-light-grey-1);
      flex-basis: 65%;
      flex-shrink: 0;
      .table-head,
      .table-body .table-row {
        @extend %p-l-half;
      }
      .table-head {
        border-top-left-radius: var(--half-radius);
        .text {
          @extend %font-12-pb;
          @extend %c-white-10;
        }
      }
      .table-body .text {
        @extend %c-black-6;
      }
      .table-row {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 17% 17% auto;
        gap: var(--quarter-space);
      }
    }
    &-right {
      border-right: .1rem solid var(--c-light-grey-1);
      border-top-right-radius: var(--half-radius);
      flex-basis: 35%;
      overflow-x: auto;
      overflow-y: hidden;
      .table-head,
      .table-body {
        width: fit-content;
      }
      .table-head,
      .table-body .table-row {
        @extend %p-r-half;
      }
      .table-head {
        .table-data {
          @extend %p-y-half;
          &:not(:last-child) {
            border-right: .1rem solid hsl(var(--hsl-c-white) / .4);
          }
        }
      }
      .table-body {
        .text {
          line-height: 1.4;
          @extend %c-blue-10;
        }
        .table-data:not(:last-child) .table-data-wrapper {
          border-right: .1rem solid var(--c-light-grey-1);
        }
      }
      .table-data {
        width: 20.3rem;
        &:last-child {
          width: 12.5rem;
          @extend %p-r-zero;
          .rounds-details {
            grid-template-areas: 'a c'
              'b .';
          }
        }
        &-wrapper {
          @extend %p-x-full;
        }
      }
    }
    &-head {
      height: 7.6rem;
      @extend %c-blue-bg-10;
    }
    &-body {
      .table-row {
        height: 9.5rem;
      }
    }
    &-row {
      border-bottom: 0;
    }
    &-data {
      @extend %h-100;
      @extend %p-x-zero;
      &-wrap {
        gap: var(--half-space);
        @extend %flex-n-c;
        .img {
          min-width: 3.5rem;
          width: 3.5rem;
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center top;
          @extend %circle-radius;
        }
      }
    }
    &-origin {
      gap: var(--quarter-space);
      @extend %flex-n-c;
      .image {
        width: 1.6rem;
        min-width: 1.6rem;
        aspect-ratio: 1;
        @extend %circle-radius;
      }
      .label {
        @extend %c-blue-1-10;
        @extend %font-10;
      }
    }
  }
  .text {
    line-height: 1;
    @extend %text-left;
  }
  .rounds {
    margin-bottom: 1.2rem;
    @extend %c-white-10;
    @extend %font-12;
    @extend %text-center;
    &-details {
      display: grid;
      gap: var(--half-space) var(--full-space);
      grid-template-areas: 'a c e'
        'b d .';
      grid-auto-columns: 4.6rem;
      .text {
        @extend %c-white-8;
        @extend %font-10;
        &:first-child {
          grid-area: a;
        }
        &:nth-child(2) {
          grid-area: b;
        }
        &:nth-child(3) {
          grid-area: c;
        }
        &:nth-child(4) {
          grid-area: d;
        }
        &:nth-child(5) {
          grid-area: e;
          @extend %text-center;
        }
      }
    }
  }
  .team-info {
    @extend %text-left;
  }
  .name {
    font: 1.2rem/1.5 $font-pb;
    @extend %m-b-quarter;
    @extend %d-block;
    @extend %c-blue-10;
  }
}
@media (min-width:$tablet-min-width) {
  .waf-bracket-table {
    .name {
      font-size: 1.6rem;
    }
    .table {
      .text {
        font-size: 1.4rem;
      }
      &-left {
        flex-basis: 45%;
      }
      &-right {
        flex-basis: 55%;
        .table-head,
        .table-body {
          width: 100%;
          .table-data {
            &:not(:last-child) {
              min-width: 20.3rem;
              width: auto;
              flex: 1;
              .table-data-wrapper {
                width: 100%;
              }
            }
          }
        }
      }
      &-data-wrap .img {
        min-width: 4.6rem;
      }
      &-origin {
        gap: var(--half-space);
        .label {
          font-size: 1.2rem;
        }
      }
    }
    .rounds {
      font-size: 1.4rem;
      &-details {
        grid-auto-columns: 1fr;
        .text {
          font-size: 1.2rem;
        }
      }
    }
  }
}
@media (min-width:$desktop-min-width) {
  .waf-bracket-table {
    .table {
      &-left {
        flex-basis: 35%;
        .table-head {
          padding-left: 2rem;
        }
        .table-body .table-row {
          padding-left: 2rem;
        }
      }
      &-right {
        flex-basis: 65%;
        .table-head {
          padding-right: 2rem;
        }
        .table-body .table-row {
          padding-right: 2rem;
        }
      }
      &-data-wrap {
        gap: 2rem;
      }
    }
  }
}